<script>
import AppModal from '@/components/layout/AppModal.vue'
import AppButtonLayout from '@/components/buttons/AppButtonLayout.vue'

export default {
  name: 'MontantMprModal',
  components: { AppButtonLayout, AppModal },
  props: {
    buttonLabel: {
      type: String,
      required: true
    }
  }
}
</script>

<template>
  <app-modal theme="training">
    <template v-slot:modal-button>
      <slot name="button">
        <app-button-layout
          class="modal-button"
          color="alternate-secondary"
          :small="true"
        >
          {{ buttonLabel }}
        </app-button-layout>
      </slot>
    </template>
    <template v-slot:content>
      <div class="modal-wrapper">
        <img :src="require('@/assets/module3/part2/modal-montants-forfaitaires.png')" :alt="$t('global.imageAlts.montantsMpr')">
      </div>
    </template>
  </app-modal>
</template>

<style scoped lang="scss">
.modal-wrapper {
  img {
    width: 100%;
  }
}
</style>
